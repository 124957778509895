// Helpers
import { classNames } from 'helpers/classNames';

// Hooks
import useBrowser from 'hooks/useBrowser';

// Types
import { profileThemes } from 'types/customizationTypes';

// Components
import DefaultAvatar from 'components/tailwind/avatars/DefaultAvatar';

type AvatarProps = {
  profilePicture?: string;
  shape?: 'circle' | 'square' | 'hexagon';
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
  theme?: keyof typeof profileThemes;
};

const Avatar: React.FC<AvatarProps> = ({
  profilePicture,
  shape = 'circle',
  size = 'md',
  className,
  theme = 'default'
}) => {
  const { isSafari } = useBrowser();

  const avatarSize = {
    sm: 'min-h-[40px] max-h-[40px] min-w-[40px] max-w-[40px]',
    md: 'min-h-[50px] max-h-[50px] min-w-[50px] max-w-[50px]',
    lg: 'h-20 w-20',
    xl: 'h-[100px] w-[100px]',
    '2xl': 'h-[112px] w-[112px]'
  };

  const avatarPrideSize = {
    sm: 'min-h-[38px] max-h-[38px] min-w-[38px] max-w-[38px]',
    md: 'min-h-[47px] max-h-[47px] min-w-[47px] max-w-[47px]',
    lg: 'min-h-[77px] max-h-[77px] min-w-[77px] max-w-[77px]',
    xl: 'h-[94px] w-[94px]',
    '2xl': 'h-[106px] w-[106px]'
  };

  const avatarShape = {
    circle: 'rounded-full',
    square: 'rounded',
    hexagon: 'hexagon-clip-path'
  };

  const borderPrideSize = {
    sm: 'p-[1px]',
    md: 'p-[1.5px]',
    lg: 'p-[1.5px]',
    xl: 'p-[3px]',
    '2xl': 'p-[3px]'
  };

  return (
    <div
      className={classNames(
        className,
        theme === 'pride' &&
          `rounded-pride-border w-fit ${
            borderPrideSize[size]
          } border-solid border-transparent ${
            shape === 'hexagon' && !isSafari
              ? avatarShape[shape]
              : avatarShape['circle']
          }`
      )}
    >
      {profilePicture ? (
        <img
          className={classNames(
            theme === 'pride' ? avatarPrideSize[size] : avatarSize[size],
            shape === 'hexagon' && !isSafari
              ? avatarShape[shape]
              : avatarShape['circle'],
            shape !== 'hexagon' && avatarShape[shape],
            'object-cover  object-center'
          )}
          src={profilePicture}
          alt="user picture"
        />
      ) : (
        <DefaultAvatar size={size} shape={shape} />
      )}
    </div>
  );
};

export default Avatar;
