// Components
import Avatar from 'components/tailwind/avatars/Avatar';
import DefaultAvatar from 'components/tailwind/avatars/DefaultAvatar';

// Types
import { profileThemes } from 'types/customizationTypes';

// Helpers
import { classNames } from 'helpers/classNames';

type AvatarWithTextProps = {
  profilePicture?: string;
  username: string;
  name?: string;
  hexagonShape?: boolean;
  underlineLink?: boolean;
  className?: string;
  onClick?: () => void;
  theme?: keyof typeof profileThemes;
};

const AvatarWithText: React.FC<AvatarWithTextProps> = ({
  profilePicture,
  username,
  name,
  hexagonShape = false,
  underlineLink = true,
  className,
  onClick,
  theme = 'default'
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        onClick && 'cursor-pointer',
        'flex w-full items-center truncate',
        className
      )}
    >
      {profilePicture ? (
        <Avatar
          profilePicture={profilePicture}
          shape={hexagonShape ? 'hexagon' : 'circle'}
          size="sm"
          theme={theme}
        />
      ) : (
        <DefaultAvatar size="sm" shape={hexagonShape ? 'hexagon' : 'circle'} />
      )}

      <div className="ml-2 truncate">
        <h4 className="truncate text-base font-medium text-gray-700">
          {!name ? 'N/A' : name}
        </h4>

        <h5
          className={classNames(
            underlineLink && 'underline underline-offset-1',
            'truncate text-xs font-medium text-primary'
          )}
        >
          {`mis.fans/${username}`}
        </h5>
      </div>
    </div>
  );
};

export default AvatarWithText;
