const formatPublicKey = (publicKey: string, charNumber = 4) => {
  return (
    publicKey.slice(0, charNumber) +
    '...' +
    publicKey.slice(Math.max(publicKey.length - charNumber, 0))
  );
};

const getFirstNCharacters = (string: string, numberOfCharacters: number) => {
  return string.substring(0, numberOfCharacters);
};

const replaceSpacesWithDashes = (string: string) => {
  return string.replace(/\s/g, '-').toLocaleLowerCase();
};

const getValueAfterLastSlash = (string: string) => {
  return string.split('/').pop();
};

const formatSecondsToVideoDuration = (seconds: number) => {
  return (
    (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds
  );
};

export {
  formatPublicKey,
  getFirstNCharacters,
  replaceSpacesWithDashes,
  getValueAfterLastSlash,
  formatSecondsToVideoDuration
};
