// Types
import { IUser } from 'types/User';
import { Account } from 'types/accounts';

// Helpers
import { getFirstNCharacters } from 'helpers/formatData';

export const isProfileImageHexagon = (
  user: IUser | Account | Pick<IUser, 'linked_accounts' | 'is_verified'>
) => {
  if (!user) {
    return false;
  } else {
    return user.is_verified && user.linked_accounts.phantom.addr.length > 0;
  }
};

export const isValidUrl = (url: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(url);
};

export const isLink = (socialMedia: string) => {
  return getFirstNCharacters(socialMedia, 5) === 'https';
};
