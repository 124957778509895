// Libraries
import React from 'react';

// Utils
import classNames from 'utils/class-names';

type BasicButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  title: React.ReactNode;
  onClick?: () => void | Promise<void> | Promise<string | void>;
  cn?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  size?: 'mini' | 'small' | 'medium' | 'large' | 'xlarge';
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  loadingChildren?: React.ReactNode;
};

const BasicButton = ({
  leftIcon,
  rightIcon,
  type = 'button',
  title,
  onClick,
  disabled,
  cn = '',
  className = '',
  loading,
  size = 'medium',
  loadingChildren
}: BasicButtonProps) => {
  const buttonSize = {
    mini: 'text-xs py-[6px] px-[10px]',
    small: 'text-sm py-2 px-4',
    medium: 'text-base py-3 px-6',
    large: 'text-lg',
    xlarge: 'text-xl'
  };

  const spinnerSize = {
    mini: 'h-5 w-5 border-4',
    small: 'h-6 w-6 border-4',
    medium: 'h-6 w-6 border-4',
    large: 'h-6 w-6 border-4',
    xlarge: 'h-6 w-6 border-4'
  };

  return (
    <button
      type={type}
      className={classNames(
        className,
        cn,
        buttonSize[size],
        loading ? 'cursor-not-allowed hover:bg-primary' : 'cursor-pointer',
        disabled
          ? 'cursor-not-allowed opacity-50'
          : 'cursor-pointer hover:bg-primary-800',
        'flex items-center justify-center rounded-md border border-transparent bg-primary font-medium text-white shadow-sm active:ring-2 active:ring-primary active:ring-offset-2 disabled:active:ring-transparent'
      )}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <span className="flex items-center gap-[10px]">
          <div
            className={classNames(
              spinnerSize[size],
              'loader-white border-white border-opacity-40'
            )}
          />
          {loadingChildren}
        </span>
      ) : (
        <span className="flex items-center gap-3">
          {leftIcon}
          {title}
          {rightIcon}
        </span>
      )}
    </button>
  );
};

export default BasicButton;
