import { useState, useEffect } from 'react';

type browserList = {
  isChrome: boolean;
  isFirefox: boolean;
  isSafari: boolean;
  isOpera: boolean;
  isIE: boolean;
  isEdge: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const detectBrowser = () => {
  const isChrome =
    /Chrome/.test(window.navigator.userAgent) &&
    /Google Inc/.test(window.navigator.vendor);
  const isFirefox = /Firefox/.test(window.navigator.userAgent);
  const isSafari =
    /Safari/.test(window.navigator.userAgent) &&
    /Apple Computer/.test(window.navigator.vendor);
  const isOpera = /Opera/.test(window.navigator.userAgent);
  const isIE = /MSIE/.test(window.navigator.userAgent);
  const isEdge = /Edge/.test(window.navigator.userAgent);
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  const isTablet = /iPad|iPod|Tablet PC/i.test(window.navigator.userAgent);
  const isDesktop = !isMobile && !isTablet;

  return {
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isMobile,
    isTablet,
    isDesktop
  };
};

const useWindowDimensions = () => {
  const [browser, setBrowser] = useState<browserList>({
    isChrome: false,
    isFirefox: false,
    isSafari: false,
    isOpera: false,
    isIE: false,
    isEdge: false,
    isMobile: false,
    isTablet: false,
    isDesktop: false
  });

  useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  return browser;
};

export default useWindowDimensions;
