// Helpers
import { classNames } from 'helpers/classNames';

// Hooks
import useBrowser from 'hooks/useBrowser';

// Components
import UserFaRegular from 'components/tailwind/icons/UserFaRegular';

type DefaultAvatarProps = {
  shape?: 'circle' | 'square' | 'hexagon';
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
};

const DefaultAvatar: React.FC<DefaultAvatarProps> = ({
  shape = 'circle',
  size = 'md'
}) => {
  const { isSafari } = useBrowser();

  const avatarSize = {
    sm: 'h-10 w-10',
    md: 'h-[50px] w-[50px]',
    lg: 'h-20 w-20',
    xl: 'h-[100px] w-[100px]',
    '2xl': 'h-[112px] w-[112px]'
  };

  const avatarShape = {
    circle: 'rounded-full',
    square: 'rounded',
    hexagon: 'hexagon-clip-path'
  };

  const avatarIconSize = {
    sm: 'w-[22px] h-[22px]',
    md: 'h-[22px] w-[22px]',
    xl: 'w-12 h-12',
    '2xl': 'w-12 h-12'
  };

  return (
    <div
      className={classNames(
        avatarSize[size],
        shape === 'hexagon' && !isSafari
          ? avatarShape[shape]
          : avatarShape['circle'],
        shape !== 'hexagon' && avatarShape[shape],
        'flex items-center justify-center bg-primary-50'
      )}
    >
      <UserFaRegular
        className={classNames(avatarIconSize[size], 'text-primary')}
        aria-hidden="true"
      />
    </div>
  );
};

export default DefaultAvatar;
