// Libraries
import React from 'react';
import Link from 'next/link';

// Helpers
import classNames from 'utils/class-names';

// Components
import Spacer from 'components/tailwind/layout/Spacer';
import SpinnerLoader from 'components/tailwind/loaders/SpinnerLoader';

type WhiteButtonProps = {
  type?: 'button' | 'submit' | 'link' | 'nextLink';
  target?: '_blank' | 'normal';
  href?: string;
  title: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  size?: 'mini' | 'small' | 'medium' | 'large' | 'xlarge';
  leftIcon?: React.ReactNode;
  loading?: boolean;
  loadingChildren?: React.ReactNode;
  id?: string;
};

const WhiteButton = ({
  type = 'button',
  target = '_blank',
  href,
  title,
  onClick,
  disabled,
  className = '',
  size = 'medium',
  leftIcon,
  loading,
  loadingChildren,
  id
}: WhiteButtonProps) => {
  const buttonSize = {
    mini: 'text-xs',
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg',
    xlarge: 'text-xl'
  };

  const buttonClassName = classNames(
    className,
    buttonSize[size],
    loading ? 'cursor-not-allowed hover:bg-white' : 'cursor-pointer',
    disabled
      ? 'cursor-not-allowed opacity-50'
      : 'cursor-pointer hover:bg-gray-50 ',
    'inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-3 font-medium text-gray-700 shadow-sm active:ring-2 active:ring-primary disabled:active:ring-transparent active:ring-offset-2'
  );

  if (type === 'link') {
    if (target === '_blank') {
      return (
        <a id={id} href={href} className={buttonClassName} target="_blank">
          {leftIcon && (
            <>
              {leftIcon}
              <Spacer className="w-2" />
            </>
          )}
          {title}
        </a>
      );
    }

    return (
      <a id={id} href={href} className={buttonClassName} target="_self">
        {leftIcon && (
          <>
            {leftIcon}
            <Spacer className="w-2" />
          </>
        )}
        {title}
      </a>
    );
  }

  if (type === 'nextLink') {
    return (
      <Link href={href}>
        <a className={buttonClassName}>
          {leftIcon && (
            <>
              {leftIcon}
              <Spacer className="w-2" />
            </>
          )}
          {title}
        </a>
      </Link>
    );
  }

  return (
    <button
      id={id}
      type={type}
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <span className="flex items-center gap-[10px]">
          <SpinnerLoader color="#542AE2" />
          {loadingChildren}
        </span>
      ) : (
        <span className="flex items-center gap-2">
          {leftIcon}
          {title}
        </span>
      )}
    </button>
  );
};

export default WhiteButton;
