import React from 'react';

interface ISpinnerLoaderProps {
  className?: string;
  color?: string;
  size?: number;
}

const SpinnerLoader = ({
  className = '',
  color = '#FFFFFF',
  size = 5
}: ISpinnerLoaderProps) => {
  const borderClassName: string = `3px solid ${color}`;

  return (
    <div
      className={`h-${size} w-${size} animate-spin rounded-full ${className}`}
      style={{
        borderTop: borderClassName,
        borderRight: borderClassName,
        borderBottom: borderClassName,
        borderLeft: '3px solid transparent'
      }}
    />
  );
};

export default SpinnerLoader;
