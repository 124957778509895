// Libraries
import React from 'react';

// Helpers
import classNames from 'utils/class-names';
import Spacer from 'components/tailwind/layout/Spacer';

export enum variantType {
  primary = 'primary',
  red = 'red',
  gray = 'gray'
}

type SecondaryButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  title?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  variant?: variantType;
  loading?: boolean;
  size?: 'mini' | 'small' | 'medium' | 'large' | 'xlarge';
  LeftIcon?: React.ReactNode;
  loadingChildren?: React.ReactNode;
};

const SecondaryButton = ({
  type = 'button',
  title = '',
  onClick,
  disabled,
  className = '',
  variant = variantType.primary,
  loading,
  size = 'medium',
  LeftIcon,
  loadingChildren
}: SecondaryButtonProps) => {
  const buttonSize = {
    mini: 'text-xs py-2 px-3',
    small: 'text-sm py-2 px-4',
    medium: 'text-base py-3 px-6 leading-6',
    large: 'text-lg py-2 px-3',
    xlarge: 'text-xl py-2 px-3'
  };

  const loadingVariant = {
    primary: 'hover:bg-primary-100',
    red: 'hover:bg-red-100',
    gray: 'hover:bg-gray-100'
  };

  const notLoadingVariant = {
    primary: 'hover:bg-primary-200',
    red: 'hover:bg-red-200',
    gray: 'hover:bg-gray-200'
  };

  return (
    <button
      type={type}
      className={classNames(
        className,
        buttonSize[size],
        loading
          ? `cursor-not-allowed ${loadingVariant[variant]}`
          : `cursor-pointer ${notLoadingVariant[variant]}`,
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        variant === variantType.primary &&
          'bg-primary-100 text-primary-800 active:ring-primary disabled:bg-primary-100',
        variant === variantType.red &&
          'bg-red-100 text-red-700 active:ring-red disabled:bg-red-100',
        variant === variantType.gray &&
          'border border-gray-200 bg-gray-100 text-gray-700 active:ring-primary disabled:bg-gray-100',
        'inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent text-sm font-medium shadow-sm active:outline-none active:ring-2 active:ring-offset-2 disabled:active:ring-0 disabled:active:ring-offset-0'
      )}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <span className="flex items-center gap-[10px]">
          <div
            className={`loader-${variant} h-6 w-6 rounded-full border-4 border-white border-opacity-40`}
          />
          {loadingChildren}
        </span>
      ) : (
        <span className="flex items-center gap-[10px]">
          {LeftIcon && LeftIcon}

          {title}
        </span>
      )}
    </button>
  );
};

export default SecondaryButton;
